import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#007AFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    success: {
      main: '#34C759',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '12px',
      fontWeight: 600,
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
    },
    
    allVariants: {
      color: '#FFFFFF',
    }
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          height: 7,
          backgroundColor: 'rgba(0,0,0,0.3)',
          border: '1px solid rgba(255,255,255,0.4)',
        },
        bar1Determinate: {
          borderRadius: 50,
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFFFF',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFFFF',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFFFF',
          },
          '& .MuiInputBase-input': {
            color: '#FFFFFF', // Set input text color to white
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF',
        },
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9',
          },
          '&.Mui-focused *': {
            borderColor: '#D9D9D9',
          },
          '& .MuiInputBase-input': {
            color: '#1E1E1E',
          },
          '& .MuiInputLabel-root': {
            color: '#1E1E1E',
          }
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '#layout-container': {
            display: 'none',
          },
        },
      },
    },
  },
});

export default theme;
