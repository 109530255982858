import React, { useEffect, useState } from 'react';
import { Container, FormControl, FormControlLabel, FormLabel, Radio, FormGroup, Button, Typography, Alert, Box, CardMedia, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Gym } from '../../api';
import './pages.css';

type TrainingFormProps = {
  onSubmit: (training: { goal: string, motivation: string, duration: number }) => void;
  gym: Gym;
  client: { name: string };
};

const TrainingForm: React.FC<TrainingFormProps> = ({ onSubmit, gym, client }) => {
  const { t } = useTranslation();
  const [selectedMuscularGroups, setSelectedMuscularGroups] = useState<string[]>([]);
  const [motivation, setMotivation] = useState<string>('');
  const [time, setTime] = useState<number>(60);
  const [page, setPage] = useState(0);
  const [transitionClass, setTransitionClass] = useState<string>('page-enter');
  const [opacity, setOpacity] = useState(0);

  const handleGroupClick = (group: string) => {
    setSelectedMuscularGroups((prev) =>
      prev.includes(group) ? prev.filter((g) => g !== group) : [...prev, group]
    );
  };
  const handleBack = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  }

  const handleNext = () => {
    setTransitionClass('page-exit');
    setTimeout(() => {
      setPage((prev) => prev + 1);
      setTransitionClass('page-enter');
    }, 300);
  };
  const handleSubmit = () => {
    // Handle form submission
    onSubmit({ goal: selectedMuscularGroups.join(','), motivation, duration: time });
  };
  const canGoNext = page === 1 ? selectedMuscularGroups.length > 0 : page === 2 ? !!motivation : true;
  useEffect(() => {
    setOpacity(1);
    setTimeout(() => {
      handleNext();
    }, 100);
  }, []);
  return (
    <Box display="flex" flexDirection="column" p={3} pt={0} sx={{ minHeight: '100vh', transition: 'opacity 0.4s ease-in', opacity }}>
      <Box width="100%" height="250px" display="flex" alignItems="center" justifyContent="center">
        <CardMedia
          component="img"
          loading="lazy"
          height="120"
          image={gym?.logoUrl || '/logo_2_out.png'}
          sx={{ objectFit: 'contain' }}
        />
      </Box>
      {<Typography variant="body1" textAlign="center">{t('ExpressDayTraining.planificationFor', { name: client.name })}😊</Typography>}
      <Box>
      <Box className={`page ${transitionClass}`}>   
        {page === 1 && (
          <Box>
            <Typography sx={{ height: 120 }} variant="h2" justifyContent="center" display="flex" alignItems="center" textAlign="center">{t('ExpressDayTraining.whatGoalToday')}</Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
              <FormGroup>
                {[
                  t('ExpressDayTraining.legsGlutesCore'),
                  t('ExpressDayTraining.peck'),
                  t('ExpressDayTraining.shoulders'),
                  t('ExpressDayTraining.backend'),
                  t('ExpressDayTraining.arms'),
                  t('ExpressDayTraining.tonificateBody'),
                  t('ExpressDayTraining.lowFat'),
                ].map(group => (
                  <Button
                    key={group}
                    variant={selectedMuscularGroups.includes(group) ? 'contained' : 'outlined'}
                    fullWidth
                    size='medium'
                    color="secondary"
                    onClick={() => handleGroupClick(group)}
                    sx={{ mx: 0, my: 0.5 }}
                  >
                    {group}
                  </Button>
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        )}
        {page === 2 && (
          <Box>
            <Typography sx={{ height: 120 }} variant="h2" justifyContent="center" display="flex" alignItems="center" textAlign="center">
              {t('ExpressDayTraining.motivation')}
            </Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
              {[
                t('ExpressDayTraining.lowMotivation'),
                t('ExpressDayTraining.midMotivation'),
                t('ExpressDayTraining.highMotivation')
              ].map(motiv => (
                <Button
                  key={motiv}
                  variant={motivation === motiv ? 'contained' : 'outlined'}
                  fullWidth
                  size='large'
                  color="secondary"
                  onClick={() => setMotivation(motiv)}
                  sx={{ mx: 0, my: 1 }}
                >
                  {motiv}
                </Button>
              ))}
            </FormControl>
          </Box>
        )}
        {page === 3 && (
          <Box>
            <Typography sx={{ height: 120 }} variant="h2" justifyContent="center" display="flex" alignItems="center" textAlign="center">
              {t('ExpressDayTraining.timeToday')}
            </Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
              {[
                30,45,60,75,90
              ].map(motiv => (
                <Button
                  variant={time === motiv ? 'contained' : 'outlined'}
                  fullWidth
                  key={motiv}
                  size='large'
                  color="secondary"
                  onClick={() => setTime(motiv)}
                  sx={{ mx: 0, my: 1 }}
                >
                  {t(`GoalForm.${motiv}minutes`)}
                </Button>
              ))}
            </FormControl>
          </Box>
          )}
        </Box>
      </Box>
      <Box bottom={10} left={0} marginTop="auto" display="flex" flexDirection="column" alignItems="center" width="100%">
        <Typography variant="body1" sx={{ marginTop: 3, color: '#D0BCFF', fontWeight: '600', width: '100%', textAlign: 'center' }}>
          {t('ExpressDayTraining.pageOf', { current: page, total: 3 })}
        </Typography>
        <Box display="flex" p={0} pt={1} width="100%">
          <Button variant="contained" color="secondary" sx={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={handleBack}>{t('ExpressDayTraining.back')}</Button>
        
          {page < 3 ? (
            <Button disabled={!canGoNext} variant="contained" color="primary" sx={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={handleNext}>{t('ExpressDayTraining.next')}</Button>
          ) : (
            <Button variant="contained" color="success" sx={{ flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={handleSubmit}>{t('ExpressDayTraining.next')}</Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TrainingForm;