import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import ProfilePage from "./pages/ProfilePage";
import Programs from "./pages/Programs";
import ProgramsAdd from "./pages/ProgramsAdd";
import TrainingClass from "./pages/TrainingClass";
import TrainingClasses from "./pages/TrainingClasses";
import TrainingClassesAdd from "./pages/TrainingClassesAdd";
import { GlobalStyles } from "@mui/system";
import TrainingSessionPage from "./pages/TrainingSession";
import { SessionContextProvider } from "./contexts/sessionContext";
import SignedIn from "./containers/SignedIn";
import SignInContainer from "./containers/SignInContainer";
import { UserContextProvider } from "./contexts/userContext";
import HistoryPage from "./pages/HistoryPage";
import ClientsAndTrainingsPage from "./pages/ClientsAndTrainingsPage";
import ClientView from "./pages/ClientView";
import ClassesAndRoomsPage from "./pages/ClassesAndRoomsPage";
import RankingPage from "./pages/RankingPage";
import ProgramsAddAi from "./pages/ProgramsAddAI";
import WorkoutWeeks from "./pages/WorkoutWeeksPage";
import WorkoutWeeksAddAiPage from "./pages/WorkoutWeeksPageAddAi";
import WorkoutWeekPage from "./pages/WorkoutWeekPage";
import SignupContainer from "./containers/SignupContainer";
import VerifyPage from "./pages/VerifyPage";
import ConversationsPage from "./pages/ConversationsPage";
import ConversationPage from "./pages/ConversationPage";
import MultipleTrainingsPage from "./pages/MultipleTrainingsPage";
import MultipleTrainingsListPage from "./pages/MultipleTrainingsListPage";
import ContactUsPage from "./pages/ContactUsPage";
import SinglePlanifyPage from "./pages/SinglePlanifyPage";
import ClientsPage from "./pages/ClientsPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultipleTrainingsDraftPage from "./pages/MultipleTrainingsDraftPage";
import PackagesPage from "./pages/PackagesPage";
import BillingPage from "./pages/BillingPage";
import ClientGoalsPage from "./pages/ClientGoalsPage";
import ClientHealthsPage from "./pages/ClientHealthsPage";
import ClientPlanificationsPage from "./pages/ClientPlanificationsPage";
import ExpressDayTraining from "./pages/ExpressDayTraining";
import config from "./config";
import ExpressDayTrainingLoadingContainer from "./pages/ExpressDayTrainingLoading";
import ExpressQrCodePage from "./pages/ExpressQrCodePage";
import ExercisesPage from "./pages/ExercisesPage";



const PrivateRoute = ({ Component }: { Component: any }) => {
  const isAuthenticated = localStorage.getItem('token')
  return isAuthenticated ? (
      <Component />
    ) : (
      <Navigate to="/sign-in" replace />
    );
}

function App() {
  // define theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#65558F",
        contrastText: "#fafafa",
      },
      secondary: {
        main: "#9B72FE",
        contrastText: "#fafafa",
      },
      grey: { 100: '#111520', 200: '#D9D9D9' },
      common: { white: '#fafafa' },
      background: { default: '#fafafa' },
    },
    typography: {
      fontSize: 12,
      fontFamily: 'Inter, Helvetica Neue',
      fontWeightMedium: 500,
      h1: {

      },
      h2: {

      },
      h3: {
        fontSize: '1.8rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
      h4: {
        fontSize: '1.5rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
      h5: {
        fontSize: '1.2rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderBottom: '1px solid #65558F',
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
          //  overflow: 'hidden',
          }
        }
      },
      MuiContainer: {
        defaultProps: {
          sx: {
            padding: '10px',
          }
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '40px',
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontWeight: 500,
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0,0,0, 0.7)',
          }
        }
      },
    },
  });
  const GLOBALS = {
    "a[role=button]": {
      textDecoration: 'none', 
    },
  };

  const loadGoogleAPI = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        (window as any).gapi.load('auth2', () => {
          (window as any).gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          }).then(() => {
            resolve((window as any).gapi);
          }).catch((error: any) => {
            reject(error);
          });
        });
      };
      script.onerror = () => {
        reject(new Error('Failed to load Google API script'));
      };
      document.body.appendChild(script);
    });
  };
  useEffect(() => {
    loadGoogleAPI().then(() => {
      console.log('Google API loaded and initialized');
    }).catch((error) => {
      console.error('Error loading Google API', error);
    });
  }, []);

  return (
    <SessionContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={GLOBALS} />
        <CssBaseline />
        <UserContextProvider>
          <Routes>
            <Route
              path="/sign-in/*"
              element={<SignInContainer />}
            />
            <Route
              path="/verify/:id"
              element={<VerifyPage />}
            />

            <Route
              path="/signup/*"
              element={<SignupContainer />}
            />
            <Route
              key={"home"}
              path={"/"}
              element={<Home />}
            />
            <Route
              key={"single-planify"}
              path={"/single-planify"}
              element={<SinglePlanifyPage />}
            />
            <Route
              key={"contactus"}
              path={"/contactus"}
              element={<ContactUsPage />}
            />

            <Route
              key={"express-day-training"}
              path={"/express-day-training/:gymId"}
              element={<ExpressDayTraining />}
            />

            <Route
              key={"express-day-draft"}
              path={"/express-day-training/:gymId/draft/:id"}
              element={<ExpressDayTrainingLoadingContainer />}
            />
          </Routes>
        </UserContextProvider>
        <Layout>
          <Routes>
            <Route
              key={"about"}
              path={"/about"}
              element={<PrivateRoute Component={ProfilePage} />}
            />
            <Route
              key={"express"}
              path={"/express"}
              element={<PrivateRoute Component={ExpressQrCodePage} />}
            />
            <Route
              key={"programs"}
              path={"/programs"}
              element={<PrivateRoute Component={Programs} />}
            />
            <Route
              key={"programsAddOpenAi"}
              path={"/programs/openai"}
              element={<PrivateRoute Component={ProgramsAddAi} />}
            />
            <Route
              key={"programsAdd"}
              path={"/programs/add"}
              element={<PrivateRoute Component={ProgramsAdd} />}
            />
            <Route
              key={"trainingClasses"}
              path={"/training_classes"}
              element={<PrivateRoute Component={ClassesAndRoomsPage} />}
            />
            <Route
              key={"trainingClasses"}
              path={"/training_classes/:id"}
              element={<PrivateRoute Component={TrainingClass} />}
            />
            <Route
              key={"traininClassesAdd"}
              path={"/training_classes/add"}
              element={<PrivateRoute Component={TrainingClassesAdd} />}
            />
            <Route
              key={"currentRoomRanking"}
              path={"/ranking-room/:roomId"}
              element={<PrivateRoute Component={RankingPage} />}
            />
            <Route
              key={"trainingSession"}
              path={"/training_sessions/:sessionId"}
              element={<PrivateRoute Component={TrainingSessionPage} />}
            />
            <Route
              key={"clientsAndTrainings"}
              path={"/clients_and_trainings"}
              element={<PrivateRoute Component={ClientsAndTrainingsPage} />}
            />
            <Route
              key={"clientView"}
              path={"/clients_and_trainings/clients/:id"}
              element={<PrivateRoute Component={ClientView} />}
            />
            <Route
              key={"history"}
              path={"/history"}
              element={<PrivateRoute Component={HistoryPage} />}
            />
            <Route
              key={"workoutweeks"}
              path={"/workoutweeks"}
              element={<PrivateRoute Component={WorkoutWeeks} />}
            />

            <Route
              key={"workoutweek"}
              path={"/workoutweeks/:workoutWeekId"}
              element={<PrivateRoute Component={WorkoutWeekPage} />}
            />
            <Route
              key={"workoutweeksAdd"}
              path={"/workoutweeks/add"}
              element={<PrivateRoute Component={WorkoutWeeksAddAiPage} />}
            />

            <Route
              key={"conversations"}
              path={"/conversations"}
              element={<PrivateRoute Component={ConversationsPage} />}
            />
             <Route
              key={"conversations"}
              path={"/conversations/:threadId"}
              element={<PrivateRoute Component={ConversationPage} />}
            />
            <Route
              key={"multiple-trainings"}
              path={"/multiple-trainings"}
              element={<PrivateRoute Component={MultipleTrainingsListPage} />}
            />
            <Route
              key={"multiple-trainigs"}
              path={"/multiple-trainings/:id"}
              element={<PrivateRoute Component={MultipleTrainingsPage} />}
            />
            <Route
              key={"multiple-trainigs-draft"}
              path={"/multiple-trainings-draft/:id"}
              element={<PrivateRoute Component={MultipleTrainingsDraftPage} />}
            />
            <Route
              key={"clients"}
              path={"/clients"}
              element={<PrivateRoute Component={ClientsPage} />}
            />
            <Route
              key={"clients"}
              path={"/clients/:id"}
              element={<PrivateRoute Component={ClientView} />}
            />
            <Route
              key={"client-goals"}
              path={"/clients/:id/goals"}
              element={<PrivateRoute Component={ClientGoalsPage} />}
            />   
            <Route
              key={"client-healhts"}
              path={"/clients/:id/healths"}
              element={<PrivateRoute Component={ClientHealthsPage} />}
            />   
            <Route
              key={"client-planifications"}
              path={"/clients/:id/planifications"}
              element={<PrivateRoute Component={ClientPlanificationsPage} />}
            />
            <Route
              key={"packages"}
              path={"/packages"}
              element={<PrivateRoute Component={PackagesPage} />}
            />
            <Route
              key={"billing"}
              path={"/billing"}
              element={<PrivateRoute Component={BillingPage} />}
            />
            <Route
              key={"exercises"}
              path={"/exercises"}
              element={<PrivateRoute Component={ExercisesPage} />}
            />
          </Routes>
        </Layout>
      </ThemeProvider>
      </LocalizationProvider>
    </SessionContextProvider>
  );
}

export default App;