import React, { useState, FC, FormEvent, useEffect } from "react";
import { TextField, Button, Checkbox, FormControlLabel, MenuItem, Select, InputLabel, FormControl, Box, RadioGroup, Radio, Alert, DialogActions, Typography, Autocomplete } from "@mui/material";
import api, { GymExercise, GymExerciseSettings } from "../api";
import { useTranslation } from "react-i18next";

type GymExerciseFormProps = {
  initialData?: GymExercise;
  onSave: (data: GymExercise) => void;
  onCancel: () => void;
  status: string;
  gymId: number;
};

const GymExerciseForm: FC<GymExerciseFormProps> = ({ initialData, onSave, onCancel, status, gymId }) => {
  const [name, setName] = useState(initialData?.name || "");
  const [muscleGroup, setMuscleGroup] = useState(initialData?.muscleGroup || "");
  const [machineMaterial, setmachineMaterial] = useState(initialData?.machineMaterial || "");
  const [youtubeLink, setYoutubeLink] = useState(initialData?.youtubeLink || "");
  const [level, setLevel] = useState(initialData?.level || "BEGINNER");
  const [isFunctional, setIsFunctional] = useState(initialData?.isFunctional || false);
  const [isForInjuries, setIsForInjuries] = useState(initialData?.isForInjuries || false);
  const [isForStretching, setIsForStretching] = useState(initialData?.isForStretching || false);
  const [isMachineRequired, setIsMachineRequired] = useState(initialData?.isMachineRequired || false);
  const [observations, setObservations] = useState(initialData?.observations || "");
  const [exerciseSettings, setExerciseSettings] = useState<GymExerciseSettings>({ muscleGroups: [], machineMaterials: [] });
  const { t } = useTranslation();
  const handleSave = (event: FormEvent) => {
    event.preventDefault();
    onSave({
      name,
      muscleGroup,
      machineMaterial,
      youtubeLink,
      level,
      isFunctional,
      isForInjuries,
      isForStretching,
      isMachineRequired,
      observations,
      id: initialData?.id || 0,
    });
  };
  
  const handleGetSettings = async (gymId: number) => {
    const res = await api.exercises.getSettings(gymId);
    if (res.success && res.data?.machineMaterials && res.data?.muscleGroups) {
      setExerciseSettings(res.data);
    }
  }
  useEffect(() => {
    handleGetSettings(gymId);
  }, [gymId])
  return (
    <Box width="100%" px={[2, 4]} py={[1,2]}>
      <Box display="flex" gap={2} flexDirection="column">
        <TextField label={t('Exercises.exerciseName')} value={name} onChange={(e) => setName(e.target.value)} fullWidth required />
        <Box display="flex" gap={2}>
          {/*freeSolo */}
          <Autocomplete
            id="muscleGroup"
            sx={{ flex: 1 }}
            defaultValue={muscleGroup}
            inputValue={muscleGroup}
            onInputChange={(e, value) => setMuscleGroup(value)} 
            options={exerciseSettings.muscleGroups.map((option) => option)}
            renderInput={(params) => <TextField {...params} label={t('Exercises.exerciseMuscularGroup')}/>}
          />
          <Autocomplete
            id="machineMaterial"
            sx={{ flex: 1 }}
            defaultValue={machineMaterial}
            inputValue={machineMaterial}
            onInputChange={(e, value) => setmachineMaterial(value)}
            options={exerciseSettings.machineMaterials.map((option) => option)}
            renderInput={(params) => <TextField {...params} label={t('Exercises.exercisemachineMaterial')} />}
          />
        </Box>
        <TextField label={t('Exercises.exerciseYoutubeLink')} value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} fullWidth />
        <Box display="flex" gap={2}>
          <FormControl component="fieldset" sx={{ flex: 1 }}>
            <Typography variant="subtitle1">{t('Exercises.level')}</Typography>
            <RadioGroup
              aria-label="level"
              name="level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              sx={{ flexDirection: 'column' }}
            >
              <FormControlLabel value={t('HealthForm.begginerValue')} control={<Radio />} label={t('HealthForm.begginerLabel')} />
              <FormControlLabel value={t('HealthForm.mediumValue')} control={<Radio />} label={t('HealthForm.mediumLabel')} />
              <FormControlLabel value={t('HealthForm.advancedValue')} control={<Radio />} label={t('HealthForm.advancedLabel')} />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <Typography variant="subtitle1">{t('Exercises.restrictions')}</Typography>
            <FormControlLabel control={<Checkbox checked={isFunctional} onChange={(e) => setIsFunctional(e.target.checked)} />} label={t('Exercises.isFunctional')} />
            <FormControlLabel control={<Checkbox checked={isForInjuries} onChange={(e) => setIsForInjuries(e.target.checked)} />} label={t('Exercises.isForInjuries')} />
            <FormControlLabel control={<Checkbox checked={isForStretching} onChange={(e) => setIsForStretching(e.target.checked)} />} label={t('Exercises.isForStretching')} />
            <FormControlLabel control={<Checkbox checked={isMachineRequired} onChange={(e) => setIsMachineRequired(e.target.checked)} />} label={t('Exercises.isMachineRequired')} />
          </FormControl>
        </Box>
        <TextField label={t('Exercises.exerciseObservations')} value={observations} onChange={(e) => setObservations(e.target.value)} fullWidth multiline rows={4} />
      </Box>
      {status === 'error' && (
        <Alert sx={{ my: 2}} severity="error">{t('HealthForm.errorWhenSaving')}</Alert>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={status === 'loading'}>
        {t('HealthForm.update')}
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={status === 'loading'}>
          {t('HealthForm.cancel')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default GymExerciseForm;
