import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button, Container, List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, AccordionSummary, Accordion, AccordionDetails, Alert, CardMedia } from "@mui/material";
import api, { Gym } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { formattedTime } from "../utils/times";
import { AssignmentOutlined } from "@mui/icons-material";
import { PageTitle } from "../components/PageTitle";
import * as QRCode from "qrcode.react"; // Import QRCode component from 'qrcode.react'
import { useTranslation } from "react-i18next";
import { UserContext } from "../contexts/userContext";
import './ExpressQrCodePage.css';

const ExpressQrCodePage: FC<any> = (): ReactElement => {
  const [gymRequest, setGymRequest] = useState<{ data: Gym | undefined, status: string }>({ data: undefined, status: "loading" });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const getGym = async (gymId: number) => {
    const response = await api.gyms.get(gymId);
    if (response.success && response.data) {
      setGymRequest({ data: response.data, status: "success" });
    } else {
      setGymRequest({ data: undefined, status: "error" });
    }
  }
  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    getGym(user.gymId);
  }, [user])

  return (
    <>
      <Box
        flexDirection={"column"} 
        className={"no-print"}
        
      >
        <PageTitle title={t('ExpressQrCode.title')} />
        {gymRequest.data && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography sx={{ marginBottom: 3 }} variant="body1">{t('ExpressQrCode.description', { gym: gymRequest.data?.name})}</Typography>
            <QRCode.QRCodeCanvas 
              value={`${window.location.origin}/express-day-training/${gymRequest.data?.code}`}
              imageSettings={{
                src: '/logo_2_out.png',
                height: 50,
                width: 50,
                excavate: true,
              }}
              size={200}
            />
            <Alert severity="info" sx={{ marginTop: 3 }}>
              {t('ExpressQrCode.nextStep')}
            </Alert>

            <Button onClick={handlePrint}>{t('ExpressQrCode.print')}</Button>
          </Box>
        )}
      </Box>
      <Box className="only-printable" flexDirection="column" alignItems="center">
        <Container sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
          <Typography sx={{ marginBottom: 5, p: 4, textAlign: "center" }} variant="h3">{t('ExpressQrCode.description2', { gym: gymRequest.data?.name})}</Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <QRCode.QRCodeCanvas 
              value={`${window.location.origin}/express-day-training/${gymRequest.data?.code}`}
              imageSettings={{
                src: '/logo_2_out.png',
                height: 50,
                width: 50,
                excavate: true,
              }}
              size={300}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ExpressQrCodePage;