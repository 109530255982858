import { Typography, Box } from '@mui/material';
import React from 'react';

export const StarIncomplete = ({  }) => {
  return (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.5 35.1289L35.933 42L32.899 29.05L43 20.3368L29.6985 19.2132L24.5 7L19.3015 19.2132L6 20.3368L16.101 29.05L13.067 42L24.5 35.1289Z" fill="none"/>
<path d="M50 18.2905L32.025 16.7242L25 0L17.975 16.7495L0 18.2905L13.65 30.24L9.55 48L25 38.5768L40.45 48L36.375 30.24L50 18.2905ZM25 33.8526L15.6 39.5874L18.1 28.7747L9.8 21.4989L20.75 20.5389L25 10.3579L29.275 20.5642L40.225 21.5242L31.925 28.8L34.425 39.6126L25 33.8526Z" fill="black"/>
</svg>


  );
}

