import React, { ReactElement, FC, useState, useContext, useEffect, useRef } from "react";
import { Alert, Card, CardMedia, CardContent, CardActions, CardActionArea, AlertTitle, Box, Button, Chip, Divider, FormControl, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import api, { PaymentMethod, ProgramStep, Room, StripeCustomer, Subscription } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { formatDate, formatToLongDate } from "../utils/dates";
import { Accessibility, MonitorHeart } from "@mui/icons-material";
import UserFormContainer from "../containers/UserFormContainer";
import { useTranslation } from "react-i18next";
import PartyExplosion from "../components/common/PartyExplosion";
import PaymentsContainer from "../containers/PaymentsContainer";
import SignupTutorial from "../components/tutorial/SignupTutorial";
import { GymCard } from "../components/profile/GymCard";

type PMState = {
  status: 'initial' | 'loading' | 'success' | 'error';
  error?: string;
  data?: StripeCustomer;
}
export type PRState = {
  status: 'initial' | 'loading' | 'success' | 'error';
  error?: string;
  plan?: Subscription;
}

const Profile: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [data, setData] = useState({ bandCode: '' });
  const [remainingCredits, setRemainingCredits] = useState({ remaining: 0, status: 'loading', error: '' });
  const [planRequest, setPlanRequest] = useState<PRState>({ status: 'loading', error: '' });
  const [showThankYou, setShowThankYou] = useState(false);
  const [showFirstSignIn, setShowFirstSignIn] = useState(true);
  const { user, getUser } = useContext(UserContext);
  const [update, setUpdate] = useState({ status: 'initial', error: '' });
  const [stripeCustomer, setStripeCustomer] = useState<PMState>({ status: 'initial', error: '' });
  const [params] = useSearchParams(); 
  const creditsRef = useRef(null);
  const logoRef = useRef(null);
  const isProfessor = user.role === 'PROFESSOR'
  const canEditLogo = planRequest.status === 'success' && planRequest.plan?.product?.metadata['editLogo'] === 'true';
  const { t } = useTranslation();

  const getRemainingCredits = async (id: number) => {
    setRemainingCredits({ remaining: 0, status: 'loading', error: '' })
    const res = await api.gyms.getRemainingCredits(id);
    if (res.success && res.data) {
      setRemainingCredits({ remaining: res.data.remaining, status: 'success', error: '' })
    } else {
      setRemainingCredits({ remaining: 0, status: 'error', error: res.error })
    }
  }

  const getPlan = async (id: number) => {
    if (!user?.id) { return; }
    setPlanRequest({ status: 'loading', error: '' })
    const res = await api.users.getSubscriptions(user.id);
    if (res.success) {
      if (res.data?.length) {
        setPlanRequest({ plan: res.data[0], status: 'success', error: '' })
      } else {
        setPlanRequest({ plan: undefined, status: 'success', error: '' })
      }
    } else {
      setPlanRequest({ plan: undefined, status: 'error', error: res.error })
    }
  }
  const handleLinkPortal = async () => {
    if (!user?.id) { return; }
    const id = user.id;
    const res = await api.users.customerPortalLink(id);
    console.log('resss', res)
    if (res.success && res.data) {
      window.location.href = res.data.url;
    }
  }
  const handleSignOUt = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  }
  const handleSave = async () => {
    if (!user?.clientId) return;
    setUpdate({ status: 'loading', error: '' })
    const res = await api.clients.addBand(user?.clientId, data)

    if (res.success) {
      setUpdate({ status: 'success', error: ''})
      setData({ bandCode: '' })
      getUser();
      setTimeout(() => setUpdate({ status: 'initial', error: '' }), 3000);
    }else {
      setUpdate({ status: 'error', error: res.error });
    }
  }

  const getStripeCustomer = async () => {
    if (!user.id) return;
    setStripeCustomer({ status: 'loading', error: '' })
    const res = await api.stripe.customer(user.id);
    if (res.success && res.data) {
      setStripeCustomer({ status: 'success', error: '', data: res.data })
    } else {
      setStripeCustomer({ status: 'error', error: res.error})
    }
  }
  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (user.gym) {
      getRemainingCredits(user.gym.id);
      if (user.id) {
        getPlan(user.id);
      }
    }
  }, [user.gym]);

  useEffect(() => {
    const payment = params.get('payment_complete') ? params.get('payment_complete')?.includes('true') : false;
    if (payment) {
      setShowThankYou(true);
    }
    getStripeCustomer();
  }, [user.id]);

  const lastBandCode = user.client?.bands?.length  ? user.client?.bands[user.client?.bands.length-1].band.code : '';

  if (!user?.name) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50}  sx={{ mb: 2 }} animation="wave"/>
        <Skeleton variant="rectangular" width={300} height={300} sx={{ mb: 2 }} animation="wave" />
      </Box>
    )
  }
  return (
    <Box pb={3}>
      <PageTitle title={t('Profile.myProfile')} />
      <SignupTutorial user={user} gym={user.gym} creditsRef={creditsRef} logoRef={logoRef} />
      {showThankYou && (
        <>
          <Dialog open={showThankYou} hideBackdrop>
            <DialogTitle>{t('Profile.thankYou')}</DialogTitle>
            <DialogContent>
              {t('Profile.thankYouForYourPurchase')}
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowThankYou(false)}>
                Continuar
              </Button>
            </DialogActions>
          </Dialog>
          <PartyExplosion duration={10000}/>
        </>
      )}
      <Box mt={2} m={1}>
        {t('Profile.welcome')} {user.name}!
      </Box>
      {!isProfessor ? (
        <Box mt={2} m={1} display="flex" flexDirection="column">
          <UserFormContainer />
          <Divider />
          <Box>
            <Typography variant="h4">{t('Profile.healthData')}</Typography>
            <Box>
              {user.client?.clientHealths?.map(ch => (
                <Box key={`${ch.createdAt}`}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={formatToLongDate(ch.createdAt)} color="primary" />
                  </Stack>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Accessibility />
                      </ListItemIcon>
                      <ListItemText primary={`${t('Profile.weightHeight', { weight: ch.weight, height: ch.height })}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <MonitorHeart />
                      </ListItemIcon>
                      <ListItemText primary={`${t('Profile.basalMax', { basal: ch.basal, max: ch.max })}`} />
                    </ListItem>
                  </List>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Typography variant="h4">{t('Profile.devices')}</Typography>
          <Box>
            <FormControl>
              <TextField value={data.bandCode} label="N° banda" onChange={(evt) => setData((data) => ({ ...data, bandCode: evt.target.value }))} />
            </FormControl>
            <Box mt={1}>
              {update.status === 'error' && (
                <Alert severity="error">
                  <AlertTitle>{update.error}</AlertTitle>
                </Alert>
              )}
              {update.status === 'success' && (
                <Alert severity="success">
                  <AlertTitle>Banda asociada</AlertTitle>
                </Alert>
              )}
              <FormControl>
                <Button variant="contained" disabled={update.status === 'loading'} onClick={handleSave}>
                  Guardar
                </Button>
              </FormControl>
            </Box>
            {user.client?.bands?.length ? (
              <Box mt={1}>
                <Typography variant="body1">
                  <b>Code:&nbsp;</b>{lastBandCode}
                </Typography>
              </Box>
            ) : (<></>)}
          </Box>
        </Box>
      ) : (
        <Box m={2}>
          {user.gym && (
            <Box display="flex" flexDirection={['column', 'row']} gap={3}>
              <GymCard gym={user.gym} logoRef={logoRef} canEdit={canEditLogo} />
              <Card sx={{ minWidth: 250, maxWidth: 345, position: 'relative' }} ref={creditsRef}>
                <CardContent>
                  <Typography variant="h6">{t('Profile.planificationsRemaining')}</Typography>
                  {remainingCredits.status === 'loading' && (
                    <Skeleton variant="rectangular" width="100%" height={50}  sx={{ mb: 2 }} animation="wave"/>
                  )}
                  {remainingCredits.status === 'success' && (
                    <Typography variant="h1" color="black" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>{remainingCredits.remaining}</Typography>
                  )}

                  {remainingCredits.status === 'error' && (
                    <Typography variant="h1" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>-</Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Link to="/packages">
                    <Button size="small">
                      {t('Profile.seePackages')}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
              <Card sx={{ minWidth: 250, maxWidth: 345, minHeight: 230, position: 'relative' }}>
                <CardContent>
                  <Typography variant="h6">{t('Profile.yourPlan')}</Typography>
                  {planRequest.status === 'loading' && (
                    <Skeleton variant="rectangular" width="100%" height={50}  sx={{ mb: 2 }} animation="wave"/>
                  )}
                  {planRequest.status === 'success' && (
                    <Typography variant="h3" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>{planRequest.plan?.name || t('Profile.noCurrentPlan')}</Typography>
                  )}
                  {planRequest.status === 'success' && planRequest.plan?.id && (
                    <>
                      <Typography variant="body1" textAlign="center">{t('Profile.creditsPerMonth', { credits: planRequest.plan.product.metadata['planifications'] })}</Typography>
                      <Typography variant="body1" textAlign="center" sx={{ mt: 2 }}>{t('Profile.renewsAt', { renews: formatDate(planRequest.plan.started) })}</Typography>
                    </>
                  )}

                  {planRequest.status === 'error' && (
                    <Typography variant="h1" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>-</Typography>
                  )}
                </CardContent>
                <CardActions sx={{ position: 'absolute', bottom: 0}}>
                {planRequest.plan?.name ? (
                  <Button size="small" onClick={handleLinkPortal}>
                     {t('Profile.updatePlan')}
                  </Button>
                ) : (
                  <Link to="/packages">
                    <Button size="small">
                      {t('Profile.selectPlan')}
                    </Button>
                  </Link>
                )}
                </CardActions>
              </Card>
            </Box>
          )}
        </Box>
      )}

      <Divider />
      <Box mt={3} m={1}>
        <Button onClick={handleSignOUt} variant="contained" sx={{ mt: 2 }}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;