import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, Divider, ListItemSecondaryAction, Alert, AlertTitle } from "@mui/material";
import api, { TrainingClass, WorkoutWeek } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { AssignmentOutlined, FitnessCenter } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AddPlanificationTutorial from "../components/tutorial/AddPlanificationTutorial";

type WorkoutWeeksState = {
  data: WorkoutWeek[]
  error?: string
  status: string
}
const PAGE_SIZE = 20;
const WorkoutWeeks: FC<any> = (): ReactElement => {
  const [workoutWeeks, setWorkoutWeeks] = useState<Array<WorkoutWeek>>([]);
  const [request, setRequest] = useState({ page: 0, status: 'initial' });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const isProfessor = user.role === 'PROFESSOR';
  const isClient = user.role === 'CLIENT';
  const hasPlanifications =  request.status !== 'loading' &&  workoutWeeks.length > 0;

  const { t } = useTranslation();

  const getNextWorkoutWeeks = async () => {
    setRequest({ page: request.page + 1, status: "loading" })
    const res = await api.workoutWeeks.getAll(request.page, PAGE_SIZE);
    if (res.success) {
      setWorkoutWeeks(workoutWeeks.concat(res.data));
      if (res.data?.length && res.data?.length === PAGE_SIZE) {
        setRequest((values) => ({ ...values, status: 'success' }));
      }else {
        setRequest((values) => ({ ...values, status: 'end' }));
      }
    }
  }
  useEffect(() => {
    getNextWorkoutWeeks();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >

      <PageTitle title={t('WorkoutWeeks.title')} />
      <AddPlanificationTutorial gym={user.gym} hasPlanifications={hasPlanifications} first={workoutWeeks?.length ? workoutWeeks[0] : null}  />
      {isProfessor && user.validated && (
        <SpeedDial
          onClick={() => navigate("/workoutweeks/add")}
          ariaLabel="create workoutweek"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          icon={<SpeedDialIcon />}
        />
      )}
      {isProfessor && !user.validated && (
        <Alert severity="info">
          <AlertTitle>{t('WorkoutWeeks.validateToCreate')}</AlertTitle>
        </Alert>
      )}
      {(request.status === 'success' || request.status === 'end') && workoutWeeks.length === 0 && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <img src="/stocks/empty_list.png" alt="" width="300" style={{ opacity: 0.3 }}/>
        </Box>
      )}
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {workoutWeeks.map((p: WorkoutWeek) => (
          <Link key={p.id} to={`/workoutweeks/${p.id}`}>
            <ListItemButton key={p.id} >
              <ListItemAvatar>
                <AssignmentOutlined />
              </ListItemAvatar>
              <ListItemText
                primary={p.name}
                secondary={t('WorkoutWeeks.daysPerWeek', { days: p.days })}
              />
              <Divider />
            </ListItemButton>
          </Link>
        ))}
      </List>

      {request.status !== 'end' && request.status !== 'loading' && (<Button variant="contained" onClick={getNextWorkoutWeeks}>{t('WorkoutWeeks.seeMore')}</Button>)}
    </Box>
  );
};

export default WorkoutWeeks;