import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField, CardHeader, CardMedia, Box, TableCell, TableHead, TableRow, TableContainer, Table, TableBody, useTheme, IconButton, Button, Alert, AlertTitle, Fade, CssBaseline, DialogContent, Dialog, Rating } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api, { Exercise, Gym, PlanificationDay, WorkoutWeek, WorkoutWeekCreate, WorkoutWeekDraft } from '../../api'; // Adjust the import according to your project structure
import { useTranslation } from 'react-i18next';
import { extracThumbnailFromYoutubeLink, stubPlanificationExercises } from '../../utils/json.extract';
import { getColorByLevel } from '../../utils/colors';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ArrowForwardIos, CheckCircleOutlineRounded, CheckCircleRounded, CloseOutlined, VideocamOutlined, VideocamRounded } from '@mui/icons-material';
import './workoutview.css';
import { StarComplete } from '../../icons/star-complete';
import { StarIncomplete } from '../../icons/star-incomplete';

interface WorkoutWeekEditorProps {
  workoutWeekId: number;
  gymCode: string;
}

interface EditState {
  dayIndex: number | null;
  exerciseIndex: number | null;
  repIndex: number | null;
  value: string;
}
export interface ExercisesByDay {
  day: string;
  exercises: { [key: number]: Exercise };
}

const WorkoutView: React.FC<WorkoutWeekEditorProps> = ({ workoutWeekId, gymCode }) => {
  const [workoutWeekRequest, setWorkoutRequest] = useState<{ status: string, workoutWeek: WorkoutWeek | null, planification: PlanificationDay[] }>({ status: 'loading', workoutWeek: null, planification: [] });
  const [editState, setEditState] = useState<EditState>({ dayIndex: null, exerciseIndex: null, repIndex: null, value: '' });
  const [exercisesByDay, setExercisesByDay] = useState<ExercisesByDay[]>([]);
  const [exercisesIdByDay, setExercisesIdByDay] = useState<number[][]>([]); // [dayIndex][exerciseIndex]
  const [workoutDescription, setWorkoutDescription] = useState('');
  const [exercisesDone, setExercisesDone] = useState<any>({}); // id => true
  const [itsAnimating, setItsAnimating] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [feedback, setFeedback] = useState({ open: false, message: '', status: 'initial', value: -1});
  const theme = useTheme();
  const { t } = useTranslation();
  const [gym, setGym] = useState<Gym | null>();
  const workoutWeek = workoutWeekRequest.workoutWeek;
  const planification = workoutWeekRequest.planification;

  const fetchGym = async (gymId: string) => {
    const response = await api.gyms.getByCode(gymId);
    if (response.success) {
      setGym(response.data);
    }
  };
  const fetchWorkoutWeek = async (workoutWeekId: number) => {
    const response = await api.workoutWeeks.get(workoutWeekId);
    if (response.success) {

      setWorkoutRequest({ 
        status: 'success',
        workoutWeek: {
          ...response.data,
        },
        planification: stubPlanificationExercises(response.data.planification?.planification || []),
      });
    } else {
      setWorkoutRequest({ status: 'error', workoutWeek: null, planification: [] });
    }
  };
  const handleClickLink = (link?: string) => () => {
    if (link) {
      window.open(link, '_blank');
    }
  }
  const handleExerciseDoneToggle = (exerciseId: number) => () => {
    if (Object.keys(exercisesDone).length+2 === exercisesIdByDay.flat().length) {
      setFeedback({ open: true, message: '', status: 'initial', value: -1 });
    }
    setExercisesDone((prev: any) => ({ ...prev, [exerciseId]: !prev[exerciseId] }));
  }

  const handleSendFeedback = (value: number, message: string) => async () => {
    if (value >= 0) {
      setFeedback((prev) => ({ ...prev, status: 'loading' }));
      const res = await api.workoutWeeks.feedback(workoutWeekId, value, message);
      if (res.success) {
        setFeedback({ open: false, message: '', status: 'initial', value: 0 });
      }
    }
  }

  const handleToggleDescription = (shouldOpen: boolean, workoutDescription: string) => {
    if (!itsAnimating) {
      if (!workoutWeek?.description || workoutWeek.description.length < 20) { return; }
      if (shouldOpen) {
        setItsAnimating(true);
        const words = workoutWeek.description.split(' ');
        let index = 0;
        const interval = setInterval(() => {
          if (index < words.length) {
            setDisplayedText((prev) => prev + ' ' + words[index]);
            index++;
          } else {
            clearInterval(interval);
            setItsAnimating(false);
          }
        }, 50); // Adjust the interval time as needed
      } else {
        setDisplayedText('');
      }
    }
  };

  useEffect(() => {
    if (workoutWeekId) fetchWorkoutWeek(workoutWeekId);
  }, [workoutWeekId]);

  useEffect(() => {
    if (workoutWeek) {
      setExercisesByDay(planification.map((day) => ({
        ...day,
        exercises: day.exercises.reduce((acc, exercise) => ({ ...acc, [exercise.id]: exercise }), {}),
      })));
      setExercisesIdByDay(planification.map((day) => day.exercises.map((exercise) => exercise.id)));
    }
  }, [workoutWeek]);



  useEffect(() => {
    if (gymCode) {
      fetchGym(gymCode);
    }
  }, [gymCode]);
  
  if (!workoutWeek) {
    return <Box height="100%" width="100%"></Box>
  }
  return (
    <Fade in={true} timeout={800}>
      <Grid container spacing={2} mt={1} p={2} sx={{  backgroundColor: 'transparent' }}>
        <CssBaseline />
        <Box width="100%" height="250px" display="flex" alignItems="center" justifyContent="center">
          <CardMedia
            component="img"
            loading="lazy"
            height="120"
            image={gym?.logoUrl || '/logo_2_out.png'}
            sx={{ objectFit: 'contain' }}
          />
        </Box>
        <Typography width="100%" textAlign="center" variant="h2">{t('WorkoutweekViewer.hereIsYourWorkout')}</Typography>
        {exercisesByDay.map((day, dayIndex) => (
          <Grid item xs={12} key={dayIndex} sx={{ backgroundColor: 'transparent', my: 2 }}>
            <Box display="flex" flexDirection="column" p={2}>
              <Box display="flex">
                <Typography variant="body1"  sx={{ marginRight: 1 }}>{t('WorkoutweekViewer.user')}:</Typography>
                <Typography variant="body1" fontWeight="bold"> {workoutWeek.client?.user?.name} {workoutWeek.client?.user?.lastname}</Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body1"  sx={{ marginRight: 1 }}>{t('WorkoutweekViewer.age')}:</Typography>
                <Typography variant="body1" fontWeight="bold">{Math.abs(dayjs(workoutWeek.client?.user?.birthdate).diff(dayjs(), 'years'))}</Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignContent="flex-start" mt={1}>
                <Button variant="contained" sx={{ display: 'flex', justifyContent: 'space-between' }} color="secondary" onClick={() => handleToggleDescription(!displayedText, workoutDescription)}>
                  <span>{t('WorkoutweekViewer.seeYourGoal')}</span>
                  <ArrowForwardIos color="action" sx={{ transition: 'all 0.3s ease-in', transform: displayedText ? 'rotate(90deg)' : 'none' }} />
                </Button>
                <Box p={2}>
                  <Typography variant="body1" textAlign="justify" sx={{ transform: '0.1s all ease-in'}}>{displayedText}</Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h5" component="div" style={{ marginTop: 2, padding: 10, paddingLeft: 13, marginLeft: 10 }}>
              {/*planification?.length > 1 ? day.day : t(`WorkoutweekViewer.expressTitle`)*/}
            </Typography>
                {exercisesIdByDay[dayIndex].map((exerciseId, indexReal) => {
                  const exercise = day.exercises[exerciseId];
                  const exerciseIndex = exerciseId;
                  const youtubeLinke = extracThumbnailFromYoutubeLink(exercise.youtubeLink);
                  return (
                      <Card
                        sx={{ marginTop: 1, borderRadius: 0, backgroundColor: 'transparent', position: 'relative' }}
                        raised={false}
                        elevation={0}
                        className={`exercise-card ${exercisesDone[exercise.id] ? 'exercise-done' : ''}`}
                      >
                        <Typography className="box" variant="body1" color="black">
                          {indexReal+1}
                        </Typography>
                        <CardHeader title={`${exercise.name}`} sx={{ padding: 0, height: '54px', '> div > span': {fontSize: '20px' }, paddingBottom: 0, fontWeight: 'bold', pl: '90px' }} />

                        <Fade hidden={exercisesDone[exerciseId]} in={!exercisesDone[exerciseId]}>
                          <CardContent sx={{ paddingTop: 0 }}>
                            <Box display="flex" flexDirection={['column', 'row']} gap={3}>
                              <Box display="flex" flexDirection="column" flex="4" alignItems="center" position="relative" onClick={handleClickLink(exercise.youtubeLink)}>
                                {youtubeLinke ? (
                                  <CardMedia component="img" image={youtubeLinke} alt=".." width={"100%"} sx={{ opacity: 0.8 }} />
                                ) : (
                                  <Box width="100%" display="flex" alignItems="center" justifyContent="center" height="196" border="1px solid white">
                                    <Typography variant="body1" textAlign="center">{t('WorkoutweekViewer.noVideo')}</Typography>
                                  </Box>
                                )}
                                {exercise.youtubeLink && (
                                  <Box sx={{ 
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    padding: 2,
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                    color: theme.palette.secondary.main,
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}><VideocamRounded color="secondary" fontSize='small' sx={{ mr: 1 }} /> {t('WorkoutweekViewer.seeVideo')}
                                </Box>
                                )}
                              </Box>

                              <Box display="flex" flexDirection="column" flex="8" ml={0}>
                                <Typography variant="body1"><b>{t('WorkoutweekViewer.machineOrElement')}:</b> {` ${exercise.machineMaterial}`}</Typography>
                                <Typography variant="body1"><b>{t('WorkoutweekViewer.series')}:</b> {` ${exercise.sets}`}</Typography>
                                <Typography variant="body1"><b>{t('WorkoutweekViewer.reps')}:</b> {` ${exercise.reps}`}</Typography>
                                <Typography variant="body1"><b>{t('WorkoutweekViewer.muscleGroup')}:</b> {` ${exercise.muscleGroup}`}</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Fade>
                      <Box px={2}>
                        {!exercisesDone[exerciseId] ? (
                          <Button className="toggle-in" variant="text" sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }} color="secondary" onClick={handleExerciseDoneToggle(exerciseId)}>
                            <span>{t('WorkoutweekViewer.nextExercise')}</span>
                            <CheckCircleRounded color="secondary" sx={{ transition: 'all 0.3s ease-in', opacity: 0.7 }} />
                          </Button>
                        ) : (
                          <Button className="toggle-out" variant="text" sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }} color="success" onClick={handleExerciseDoneToggle(exerciseId)}>
                            <span>{t('WorkoutweekViewer.doneExercise')}</span>
                            <CheckCircleRounded color="success" sx={{ transition: 'all 0.3s ease-in' }} />
                          </Button>
                        )}
                        </Box>
                      </Card>
                    )}

            )}
          </Grid>
        ))}
        <Dialog open={feedback.open}>
          <Box position="absolute" top="0" right="0">
            <IconButton onClick={() => setFeedback({ open: false, message: '', status: 'initial', value: 0 })}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <DialogContent sx={{ maxWidth: '100%', minHeight: '350px', display: 'flex', alignItems: 'center'  }}>
            <Box>
              <Typography variant="h2" color="black" fontWeight="bold" textAlign="center">{t('WorkoutweekViewer.almostEnding')}</Typography>
              <Typography variant="body1" color="black"  textAlign="center">{t('WorkoutweekViewer.rateThisWrokout')}</Typography>  
              <Rating
                name="customized-color"
                value={feedback.value}
                onChange={(event, newValue) => {
                  setFeedback((values) => ({ ...values, value: newValue || 0 }))}
                }
                icon={<StarComplete />}
                emptyIcon={<StarIncomplete />}
              />
              {feedback.value >= 0 && feedback.value < 4 && (
                <TextField
                  label={t('WorkoutweekViewer.feedbackToImprove')}
                  value={feedback.message}
                  onChange={(e) => setFeedback((values) => ({ ...values, message: e.target.value }))}
                  multiline
                  rows={4}
                  sx={{ marginTop: 2, color: 'black' }}
                  fullWidth
                />
              )}
              <Button 
                variant="contained" 
                color="secondary" 
                disabled={feedback.value < 0 || feedback.status === 'loading'}
                onClick={handleSendFeedback(feedback.value, feedback.message)}
                sx={{ width: 80, marginTop: 2, borderRadius: '8px', backgroundColor: '#2C2C2C', color: '#F5F5F5', fontWeight: 'normal' }}>
                {t('WorkoutweekViewer.sendFeedback')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Box sx={{ mb: 2, py: 2, px: 4 }}>
          <Typography variant="body1" textAlign="justify"> {workoutWeek?.planification?.motivational}</Typography>
        </Box>
        <Box  bottom={20} left={0} width="100%" textAlign="center" display="flex" justifyContent="center">
          <Typography variant="body1" sx={{ marginTop: 3, color: '#D0BCFF', fontWeight: '300', maxWidth: 180, textAlign: 'center' }}>
            Nexus® System by Wizfit 
          </Typography>
        </Box>
      </Grid>
    </Fade>
  );
};

export default WorkoutView;