import React, { ReactElement, FC, useState, useContext, useEffect } from "react";
import { Box, Typography,Container, Tabs, Tab, AppBar, List, ListItemButton, ListItemAvatar, ListItemText, Divider, ListItem, CircularProgress, Button, Alert, AccordionDetails, Accordion, AccordionSummary, Dialog, DialogTitle, SpeedDial, SpeedDialIcon } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { useTranslation } from "react-i18next";
import { UserContext } from "../contexts/userContext";
import SearchField from "../components/common/SearchField";
import api, { Exercise, GymExercise } from "../api";
import { Link } from "react-router-dom";
import { DeleteOutlineOutlined, EditOutlined, ExpandMoreOutlined, Person } from "@mui/icons-material";
import { extracThumbnailFromYoutubeLink, getEmbeddedYoutubeUrl } from "../utils/json.extract";
import Transition from "../components/common/Transition";
import GymExerciseForm from "../components/GymExerciseForm";

type ExercisesState = {
  data: GymExercise[];
  status: "initial" | "loading" | "success" | "error";
  page: number;
  hasMore?: boolean;
};
type EditExercisesState = {
  showForm: boolean;
  editing?: GymExercise;
  status: "initial" | "loading" | "success" | "error";
  data?: GymExercise;
};
const PAGE_SIZE = 200;

const ExercisesPage: FC<any> = (): ReactElement => {
  const [exercises, setExercises] = useState<ExercisesState>({ data: [], status: "initial", page: 0 });
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [deleteRequest, setDeleteRequest] = useState<{ status: string, id?: number }>({ status: 'initial' });
  const [expanded, setExpanded] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [cloneRequest, setCloneRequest] = useState<{ status: string }>({ status: 'initial' });
  const isMobile = window.innerWidth < 600;
  const [editExerciseRequest, setEditExerciseRequest] = useState<EditExercisesState>({ status: 'initial', showForm: false });

  const getExercises = async (search = "", page = 0) => {
    setExercises((values)=> ({ ...values, status: "loading", page }));

    const exercises = await api.exercises.get(user.gymId, search, page, PAGE_SIZE);

    setExercises((values) => ({ data: values.data.concat(exercises.data || []), status: "success", page: page+1, hasMore: exercises.data?.length === PAGE_SIZE }));  
  };

  const handleSearch = (search: string) => setSearch(search);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : 0);
  };

  const handleClickEdit = (exercise: GymExercise) => () => {
    setEditExerciseRequest({ showForm: true, status: 'initial', editing: exercise });
  }

  const handleClickAdd = () => {
    setEditExerciseRequest({ showForm: true, status: 'initial' });
  }

  const handleClickDelete = (exercise: GymExercise) => async () => {
    setDeleteRequest({ status: 'loading', id: exercise.id });
    const res = await api.exercises.remove(user.gymId, exercise.id);
    if (res.success) {
      setExercises({ ...exercises, data: exercises.data.filter((e) => e.id !== exercise.id) });
      setDeleteRequest({ status: 'success' });
    } else {
      setDeleteRequest({ status: 'error' });
    }
  }

  const handleSave = async (data: GymExercise) => {
    setEditExerciseRequest({ ...editExerciseRequest, status: 'loading' });
    let res;
    const editing = !!data.id;
    if (editing) {
      res = await api.exercises.update(user.gymId, data);
    } else {
      res = await api.exercises.create(user.gymId, data);
    }
    if (res.success) {
      setEditExerciseRequest({ editing: undefined, status: 'success', showForm: false });
      if (editing) {
        setExercises({ ...exercises, data: exercises.data.map((e) => e.id === data.id ? data : e) });
      } else {
        setExercises({ ...exercises, data: [ res.data, ...exercises.data] });
      }
    } else {
      setEditExerciseRequest({ ...editExerciseRequest, status: 'error' });
    }
  };
  const handleCloseForm = () => {
    setEditExerciseRequest({ showForm: false, status: 'initial' });
  }
  const handleClickClone = async () => {
    setCloneRequest({ status: 'loading' });
    const res = await api.exercises.cloneDefaults(user.gymId);
    if (res.success) {
      getExercises(search);
      setCloneRequest({ status: 'success' });
    } else {
      setCloneRequest({ status: 'error' });
    }
  }

  useEffect(() => {
    if (user.gymId !== 0) {
      setExercises({ data: [], status: "loading", page: 0 });
      getExercises(search);
    }
  }, [user.gymId, search]);

  return (
    <Box
    >
      <PageTitle title={t('Exercises.title')} />    

      <SpeedDial
        ariaLabel={t('Clients.addClient')}
        sx={{ position: 'fixed', bottom: 75, right: 16 }}
        onClick={handleClickAdd}
        icon={<SpeedDialIcon />}
      >
      </SpeedDial>
      <SearchField placeholder={t('Exercises.searchPlaceholder')} onSearch={handleSearch} />
      {exercises.status === 'success' && exercises.data.length === 0 && (
        <Alert severity="info">
          {t('Exercises.noExercises')}
          <Button variant="contained" color="primary" disabled={cloneRequest.status === 'loading'} onClick={handleClickClone} sx={{ ml: 2 }}>
            {t('Exercises.cloneDefault')}
          </Button>  
        </Alert>

      )}
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {exercises.data.map((c: GymExercise) => (
          <Accordion key={c.id} expanded={expanded === c.id} onChange={handleChange(c.id)}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <ListItemAvatar>
                <img src={extracThumbnailFromYoutubeLink(c.youtubeLink)} alt="-" width={50} />
              </ListItemAvatar>
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {c.name}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {c.muscleGroup}
              </Typography>
            </AccordionSummary>
            {expanded === c.id && (
              <AccordionDetails>
                <Box display="flex" flexDirection={['column', 'row']} mb={2}>

                  <Box flex={1} display={['none', 'block']}>
                    {c.youtubeLink ? (
                      <iframe
                        width="560"
                        height="315"
                        src={`${getEmbeddedYoutubeUrl(c.youtubeLink, { autoplay: true, loop: true })}`}
                        title={c.name}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <Alert severity="warning">No video available</Alert>
                    )}
                  </Box>
                  <Box flex={1} display={['block', 'none']} mb={2}>
                    {c.youtubeLink ? (
                      <iframe
                        width="100%"
                        src={`${getEmbeddedYoutubeUrl(c.youtubeLink, { autoplay: true, loop: true })}`}
                        title={c.name}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <Alert severity="warning">No video available</Alert>
                    )}
                  </Box>
                  <Box  flex={1} pl={2} display="flex" flexDirection="column">
                    <Box >
                      <Typography variant="body2">
                        <strong>{t('Exercises.exerciseName')}:</strong> {c.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.exerciseMuscularGroup')}:</strong> {c.muscleGroup}
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.exerciseMachineMaterial')}:</strong> {c.machineMaterial}
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.level')}:</strong> {t(`Exercises.level${c.level}`)} 
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.exerciseObservations')}:</strong> {c.observations ? c.observations : '-'}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }}/>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">
                        <strong>{t('Exercises.isForInjuries')}:</strong> {c.isForInjuries ? t('Exercises.yes') : t('Exercises.no')} 
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.isForStretching')}:</strong> {c.isForStretching ? t('Exercises.yes') : t('Exercises.no')} 
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.isFunctional')}:</strong> {c.isFunctional ? t('Exercises.yes') : t('Exercises.no')} 
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t('Exercises.isMachineRequired')}:</strong> {c.isMachineRequired ? t('Exercises.yes') : t('Exercises.no')} 
                      </Typography>
                      
                    </Box>
                    <Box display="flex" flex="8" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" color="primary" onClick={handleClickDelete(c)} disabled={deleteRequest.status === 'loading'}>
                          
                          {deleteRequest.status === 'loading' ? <CircularProgress size={20} sx={{ marginRight: 1 }} /> : <DeleteOutlineOutlined /> }
                          {t('Exercises.remove')}
                        </Button>
                        <Button variant="text" color="primary" onClick={handleClickEdit(c)} disabled={deleteRequest.status === 'loading'}>
                          <EditOutlined /> {t('Exercises.edit')}
                        </Button>
                      </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        ))}

        {exercises.status === 'loading' && (
          <ListItem>
            <CircularProgress />
          </ListItem>
        )}
        {exercises.hasMore && exercises.status === 'success' && (
          <ListItem>
            <Button onClick={() => getExercises('', exercises.page)} variant="contained" color="primary" >
              +
            </Button>
          </ListItem>
        )}
      </List>
      
      <Dialog
        open={editExerciseRequest.showForm}
        fullScreen={isMobile}
        TransitionComponent={Transition}
        PaperProps={{ sx: { width: ['100%', '800px'], maxWidth: '100%' } }}
      >
        {editExerciseRequest.editing?.id ? <DialogTitle>{t('Exercises.editTitle')}</DialogTitle> : <DialogTitle>{t('Exercises.createTitle')}</DialogTitle>}
        <GymExerciseForm 
          onSave={handleSave}
          onCancel={handleCloseForm}
          status={editExerciseRequest.status}
          initialData={editExerciseRequest.editing}
          gymId={user.gymId}  
        />
      </Dialog>
    </Box>
  );
};

export default ExercisesPage;
