import { Alert, Box, Button, Card, CardMedia, Container, createTheme, CssBaseline, Fade, Paper, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api, { Gym } from '../api';
import LogoLoading from '../icons/logoLoading';
import TrainingForm from '../components/expressday/TrainingForm';
import '../components/expressday/background.css';
import { ArrowForwardIosOutlined, ArrowForwardOutlined, Css } from '@mui/icons-material';
import nexusTheme from '../components/expressday/nexus-theme';
import { UserContext } from '../contexts/userContext';

const ExpressDayTraining: React.FC = () => {
  const { gymId } = useParams<{ gymId: string }>();
  const [document, setDocument] = useState<string>('');
  const [gym, setGym] = useState<Gym | null>();
  const [clientRequest, setClientRequest] = useState<any>({ data: null, status: 'initial', error: null });
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user, updateUser } = useContext(UserContext);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(event.target.value);
  };
  const fetchGym = async (gymId: string) => {
    const response = await api.gyms.getByCode(gymId);
    if (response.success) {
      setGym(response.data);
    } else {
      setError('invalid_gym');
    }
  };
  const getClientByDocument = async (document: string) => {
    setClientRequest({ ...clientRequest, status: 'loading' });
    const response = await api.clients.getByDocument(document, gym?.id || 0);
    if (response.success) {
      setClientRequest({ data: response.data, status: 'success', error: null });
      updateUser({ ...response.data, gym: gym });
    } else {
      setClientRequest({ ...clientRequest, status: 'error', error: response.error });
    }
  }
  const handleClickDocument = () => {
    if (!document) return;
    getClientByDocument(document);
  }
  const handleSubmit = async (training: { goal: string, motivation: string, duration: number }) => {
    const response = await api.clients.generateExpressPlanification(clientRequest.data.id, training);
    if (response.success && response.data) {
      navigate(`/express-day-training/${gymId}/draft/${response.data.id}`);
    } else {
      setClientRequest({ ...clientRequest, status: 'error', error: response.error });
      setTimeout(() => {
        setClientRequest({ ...clientRequest, status: 'initial', error: null });
      }, 3000);
    }
  }

  useEffect(() => { 
    if (!gymId) return;
    fetchGym(gymId);
  }, [gymId]);
  
  if (!gym) {
    return error ? (
      <Box display="flex" flexDirection="column" gap={3} p={2} alignItems="center">
        <Alert severity="error">{t('ExpressDayTraining.invalidGym')}</Alert>
        <Link to="/">
          <Button variant="contained" color="primary">
            {t('ExpressDayTraining.goHome')}
          </Button>
        </Link>
      </Box>
     ) :
      <Box p={4} display="flex" alignItems="center" justifyContent="center" className="wrapper-body" height="100%">
       
      </Box>
  };
  return (
    <ThemeProvider theme={nexusTheme}>
      <CssBaseline />
      <Container className="wrapper-body" sx={{ minHeight: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '600px' }}>
        <Box width="100%">
          {!clientRequest.data && (
            <Box display="flex" flexGrow={10} flexDirection="column">
              <Fade in={true} timeout={1000}>
                <Box width="100%" height="250px" display="flex" alignItems="center" justifyContent="center">
                  <CardMedia
                    component="img"
                    loading="lazy"
                    height="120"
                    image={gym?.logoUrl || '/logo_2_out.png'}
                    sx={{ objectFit: 'contain', transition: 'all 0.5s ease-in' }}
                  />
                </Box>
              </Fade>
              <Box display="flex" flex={3} flexDirection="column" px={2} alignItems="center">
                <Typography variant="h1" textAlign="center">{t('ExpressDayTraining.title', { gym: gym?.name })}</Typography>
                <Typography variant='body1' textAlign="center">{t('ExpressDayTraining.subtitle')}</Typography>
                <TextField
                  label={t('ExpressDayTraining.document')}
                  type="number"
                  value={document}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3, marginBottom: 3 }}
                />
                {clientRequest.status === 'error' && (
                  <Box maxWidth="100%">
                    <Alert sx={{ marginTop: 1 }} severity="warning">{t('ExpressDayTraining.notFound')}</Alert>
                  </Box>
                )}
                <Button size="large" variant="contained" color="primary" sx={{ marginTop: 2, width: 200 }} disabled={clientRequest.status === 'loading'} onClick={handleClickDocument}>
                  <ArrowForwardOutlined color="secondary" fontSize="small" />  {t('ExpressDayTraining.start')}
                </Button>
                <Box display="flex" justifyContent="center" width="100%">
                  <Typography variant="body1" sx={{ marginTop: 3, color: '#D0BCFF', fontWeight: '600', maxWidth: 180, textAlign: 'center' }}>
                    {t('ExpressDayTraining.withOnlyThreeQuestions')}
                  </Typography>
                </Box>

              </Box>
              <Box position="fixed" bottom={20} left={0} width="100%" textAlign="center" display="flex" justifyContent="center">
                <Typography variant="body1" sx={{ marginTop: 3, color: '#D0BCFF', fontWeight: '300', maxWidth: 180, textAlign: 'center' }}>
                  Nexus® System by Wizfit 
                </Typography>
              </Box>
            </Box>
          )}
          {clientRequest.data && (
            <Box>
              <TrainingForm onSubmit={handleSubmit} gym={gym} client={clientRequest.data} />
              {clientRequest.status === 'error' && clientRequest.error === 'no_more_limit' && (
                <Alert severity='error'>{t('ExpressDayTraining.noMoreLimitError')}</Alert>
              )}
              {clientRequest.status === 'error' && clientRequest.error !== 'no_more_limit' && (
                <Alert severity='error'>{t('ExpressDayTraining.genericError')}</Alert>
              )}
            </Box>
          )}
        </Box>
    </Container>
    </ThemeProvider>
  );
};

export default ExpressDayTraining;
