import React, { ReactElement, FC, useEffect, useState, SyntheticEvent } from "react";
import { Box, Button, Container, List, ListItemButton, ListItemAvatar, ListItemText, Divider, ListItem, CircularProgress, SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import { formattedTime } from "../utils/times";
import { AssignmentOutlined, Person, UploadFile, Add } from "@mui/icons-material";
import SearchField from "../components/common/SearchField";
import { useTranslation } from "react-i18next";

type ClientRequestState = {
  data: any[];
  status: "initial" | "loading" | "success";
  page: number;
  hasMore?: boolean;
};
const PAGE_SIZE = 200;

const Clients: FC<any> = (): ReactElement => {
  const [clients, setClients] = useState<ClientRequestState>({ data: [], status: "initial", page: 0 });
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getClients = async (search = "", page = 0) => {
    setClients((values)=> ({ ...values, status: "loading", page }));
    
    const clients = await api.clients.get(search, page, PAGE_SIZE);
    setClients((values) => ({ data: values.data.concat(clients.data), status: "success", page: page+1, hasMore: clients.data.length === PAGE_SIZE }));
  };

  const handleSearch = (search: string) => { 
    setSearch(search);
  }
  const handleClickMore = () => getClients(search, clients.page)

  useEffect(() => {
    setClients({ data: [], status: "loading", page: 0 });
    getClients(search);
  }, [search]);


  return (
    <>

      <SpeedDial
        ariaLabel={t('Clients.addClient')}
        sx={{ position: 'fixed', bottom: 75, right: 16 }}
        onClick={() => navigate('/clients/new')}
        icon={<SpeedDialIcon />}
      >
      </SpeedDial>
      <SearchField placeholder={t('Clients.searchPlaceholder')} onSearch={handleSearch} />
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {clients.data.map((c: any) => (
          <Link key={c.id} to={`${c.id}`}>
            <ListItemButton>
              <ListItemAvatar>
                <Person />
              </ListItemAvatar>
              <ListItemText
                primary={`${c.nick}`}
                secondary={`${c.user?.name} ${c.user?.lastname}`}
              />
              <Divider />
            </ListItemButton>
          </Link>
        ))}

        {clients.status === 'loading' && (
          <ListItem>
            <CircularProgress />
          </ListItem>
        )}
        {clients.hasMore && clients.status === 'success' && (
          <ListItem>
            <Button onClick={handleClickMore} variant="contained" color="primary" >
              +
            </Button>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default Clients;