import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert, CircularProgress, AlertTitle, Grow, IconButton, ListItemText, AccordionSummary, Accordion, AccordionDetails, Chip, List, ListItem, ListItemAvatar, ListItemButton, Badge, useTheme, CardMedia, ThemeProvider, LinearProgress, CssBaseline } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { Gym, MultipleTraining, ProcessStatus, WorkoutWeek, WorkoutWeekDraft } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import LogoLoading from "../icons/logoLoading";
import WorkoutWeekEditor from "../components/workweek-editor/WorkweekEditor";
import { useWizard, WizardContextProvider } from "../contexts/PlanifyWizardContext";
import { stubPlanificationExercises } from "../utils/json.extract";
import { useTranslation } from "react-i18next";
import LogoGradient from "../icons/logoGradient";
import { Close } from "@mui/icons-material";
import WorkoutView from "../components/expressday/WorkoutView";
import '../components/expressday/background.css';
import nexusTheme from '../components/expressday/nexus-theme';

type MultipleTrainingsState = {
  data?: MultipleTraining,
  workoutWeekDraft?: WorkoutWeekDraft,
  status: string,
  error?: string | boolean,
}
type WorkoutRequestState = {
  status: string,
  workout: WorkoutWeek | null,
}

const ExpressDayTrainingLoading: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const { id = '', gymId = '' } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const [userDataLoading, setUserDataLoading] = useState<number>(0);
  const [goalsLoading, setGoaldLoading] = useState<number>(0);
  const [creatingLoading, setCreatingLoading] = useState<number>(0);
  const { user } = useContext(UserContext);
  
  const [workoutRequest, setWorkoutRequest] = useState<WorkoutRequestState>({ status: 'loading', workout: null });
  const [multipleTrainings, setMultipleTrainings] = useState<MultipleTrainingsState>({ status: "initial" });
  

  const fetchWorkout = async (id: number) => {
    const multipleTrainings = await api.multipleTrainings.getById(id);
    if (multipleTrainings.data?.status === ProcessStatus.FINISHED && multipleTrainings.data.workoutWeeks[0]?.id) {
      setCreatingLoading(100);
      setMultipleTrainings({ status: "success", data: multipleTrainings.data });
      setWorkoutRequest({ status: 'success', workout: multipleTrainings.data.workoutWeeks[0] });
      return true;
      /* pdfgenerator
      if (multipleTrainings.data?.workoutWeeks?.length && multipleTrainings.data?.workoutWeeks[0].fileUrl) {
        setWorkoutRequest({ status: 'success', workout: multipleTrainings.data.workoutWeeks[0] });
        return true;
      } else {
        setWorkoutRequest({ status: 'loading', workout: null });
      }*/
      
    } else {
      if (multipleTrainings.data?.status === ProcessStatus.ERRORED) {
        setMultipleTrainings({ status: "error", error: multipleTrainings.error });
        return true;
      }
    }
  }

  const getMultipleTrainings = async (id: number) => {
    setMultipleTrainings({ status: "loading" })
    intervalsProgress();
    const res = await fetchWorkout(id);
    
    if (res) return;
    const interval = setInterval(async () => {
      
      const shouldClear = await fetchWorkout(id);
      if (shouldClear) {
        clearInterval(interval);
      }
    }, 2500);
    
  }
  const intervalsProgress = () => {
    const values = {
      userDataLoading: 0,
      goalsLoading: 0,
      creatingLoading: 0,
    }
    const interval = setInterval(() => {
      if (values.userDataLoading++ < 100) {
        console.log('setUserDataLoading', values.userDataLoading)
        setUserDataLoading(values.userDataLoading);
      }
      if (values.userDataLoading >= 100 && values.goalsLoading++ < 100) {
        console.log('setGoaldLoading', values.goalsLoading)
        setGoaldLoading(values.goalsLoading);
      }
      if (values.goalsLoading >= 100 && values.creatingLoading++ < 200) {
        console.log('setCreatingLoading', values.creatingLoading)
        setCreatingLoading(values.creatingLoading/2);
      }
      if (creatingLoading >= 200 || (workoutRequest.status === 'success' && workoutRequest.workout?.id) ) {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }
  const handleBack = () => navigate(`/express-day-training/${gymId}`);

  useEffect(() => {
    if (id) {
      getMultipleTrainings(parseInt(id));
    }
  }, [id]);
  const client = multipleTrainings.data?.workoutWeeks[0]?.client?.user || user;
  const gym = multipleTrainings.data?.workoutWeeks[0]?.client?.user?.gym || user.gym;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      mb={4}
      className="wrapper-body"
    >
      
      {multipleTrainings.status !== 'success' && (
        <Box >
          {multipleTrainings.status === 'loading' && (
            <Box display="flex" flexDirection="column" p={3} pt={0} sx={{ minHeight: '100vh', transition: 'opacity 0.4s ease-in' }}>
              <Box width="100%" height="250px" display="flex" alignItems="center" justifyContent="center">
                <CardMedia
                  component="img"
                  loading="lazy"
                  height="120"
                  image={gym?.logoUrl || '/logo_2_out.png'}
                  sx={{ objectFit: 'contain' }}
                />
              </Box>
              {client?.name && <Typography variant="body1" textAlign="center">{t('ExpressDayTraining.planificationFor', { name: client.name })}😊</Typography>}
              <Box display="flex" flexDirection="column" pt={4}>
                <Typography variant="h1" textAlign="center">{t('ExpressDayTraining.almostThere')}</Typography>
                <Typography variant="h1" textAlign="center">{t('ExpressDayTraining.creatingWorkoutByAI')}</Typography>
                
                <Typography variant="body1" sx={{ pt: 2 , pb: 1}}>{t('ExpressDayTraining.uploadingUserData')}</Typography>
                <LinearProgress color="success" variant="determinate" value={userDataLoading} />
                <Typography variant="body1" sx={{ pt: 2, pb: 1 }} >{t('ExpressDayTraining.analyzingGoals')}</Typography>
                <LinearProgress color="success" variant="determinate" value={goalsLoading} />
                <Typography variant="body1" sx={{ pt: 2, pb: 1 }} >{t('ExpressDayTraining.creatingWorkout')}</Typography>
                <LinearProgress color="success" variant={creatingLoading >= 100 ? 'indeterminate' : 'determinate'} value={creatingLoading} />
              </Box>
            </Box>
          )}
          {multipleTrainings.status === 'error' && (
            <Box p={2}>
              <Typography variant="h6" sx={{ marginTop: 2 }}>
              {t('MultipleTrainingsDraft.errorProcessing')}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                {multipleTrainings.error}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {multipleTrainings.status === 'success' && (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" pl={1} pr={2}>
            <LogoGradient />
            <Box onClick={handleBack}>
              <Close color="inherit" fontSize="large"/>
            </Box>
          </Box>
          {workoutRequest.status === 'success' && workoutRequest.workout?.id ? (
            <WorkoutView gymCode={gymId} workoutWeekId={parseInt(workoutRequest.workout.id)} />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" width="100%" p={2}>
              <Typography variant="h4" sx={{ marginTop: 2, padding: 2 }} color={theme.palette.secondary.main}>
              {t('ExpressDayTraining.almostReady')}
              </Typography>
            </Box>
          )}

        </Box>
      )}
    </Box>
  );
};

const ExpressDayTrainingLoadingContainer: FC<any> = (): ReactElement => {
  return (
    <ThemeProvider theme={nexusTheme}>
      <CssBaseline />
      <WizardContextProvider>
        <ExpressDayTrainingLoading />
      </WizardContextProvider>
    </ThemeProvider>
  );
};

export default ExpressDayTrainingLoadingContainer;